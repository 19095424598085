<script setup lang="ts">
import { installAntlerComponent, AntlerClasses } from "@/Utils/component";
export type BadgeGroupAs = "div";

type Props = {
    as?: BadgeGroupAs;
};

const { as = "div" } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "flex items-start gap-1",
};
const { aClass } = installAntlerComponent("badge-group", {}, classes);
</script>
<template>
    <component
        :is="as"
        :class="aClass()"
    >
        <slot />
    </component>
</template>
